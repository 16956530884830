import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import StoreIcon from "@material-ui/icons/Store";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BugReportIcon from "@material-ui/icons/BugReport";
import BusinessIcon from "@material-ui/icons/Business";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import HomeIcon from "@material-ui/icons/Home";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PolicyIcon from "@material-ui/icons/Policy";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
import MenuBar from "../images/bars.svg";
import secureLocalStorage from "react-secure-storage";
import "../styles/App.css";
import "../styles/CopyRight.css";
import "../styles/Footer.css";
import "../styles/header.css";
import "../styles/MainBg.css";
import "../styles/Map.css";
import "../styles/mapview.css";
import "../styles/Responsive.css";
import "../styles/signin.css";
import "../styles/Video.css";
import LogoSite from "../images/logo.png";
const useStyles = makeStyles({
  list: {
    width: 170,
    marginTop: 70,
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  fullList: {
    width: "auto",
  },
});

const Header = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoggedIn, isAdmin, isOrganizationAdmin } = useSelector(
    (state) => state.auth
  );
  const [navMenu, setNavMenu] = useState(false);
  const MyLink = (props) => <Link to="/signup" {...props} />;
  const logOut = () => {
    history.push("/login");
    dispatch(logout());
    history.push("/login");
  };
  return (
    <div
      className={
        secureLocalStorage.getItem("organizationColor")
          ? "header-main header-main-org"
          : "header-main"
      }
      style={{
        background: secureLocalStorage.getItem("organizationColor")
          ? secureLocalStorage.getItem("organizationColor")
          : "rgba(144, 29, 161, 1)",
      }}
    >
      <div className="container-fluid">
        <div className="Logo">
          <a target={"_blank"} href="https://www.metageo.io">
            <img
              id="companyLogo1"
              style={
                secureLocalStorage.getItem("organizationColor") ||
                secureLocalStorage.getItem("organizationText")
                  ? secureLocalStorage.getItem("organizationLogo")
                    ? {
                        height: 43,
                        width: "auto",
                      }
                    : {}
                  : secureLocalStorage.getItem("organizationLogo") &&
                    JSON.parse(secureLocalStorage.getItem("restrictions"))
                      ?.whiteLabel
                  ? {
                      height: 43,
                      width: "auto",
                    }
                  : {}
              }
              src={
                secureLocalStorage.getItem("organizationColor") ||
                secureLocalStorage.getItem("organizationText")
                  ? secureLocalStorage.getItem("organizationLogo")
                    ? secureLocalStorage.getItem("organizationLogo")
                    : "/images/logo.png"
                  : secureLocalStorage.getItem("organizationLogo") &&
                    JSON.parse(secureLocalStorage.getItem("restrictions"))
                      ?.whiteLabel
                  ? secureLocalStorage.getItem("organizationLogo")
                  : "/images/logo.png"
              }
              alt="METAGEO"
            />
          </a>
        </div>
        <div className="Menu">
          <a
            onClick={() => {
              !navMenu ? setNavMenu(true) : setNavMenu(false);
            }}
            className="Bars"
          >
            <MenuIcon
              style={{
                fontSize: 26,
              }}
            />
            <Drawer
              anchor={"right"}
              open={navMenu}
              onClose={() => setNavMenu(false)}
            >
              <div
                className={clsx(classes.list)}
                role="presentation"
                onClick={() => setNavMenu(false)}
                onKeyDown={() => setNavMenu(false)}
              >
                <List className="headerList">
                  {isLoggedIn &&
                    secureLocalStorage.getItem("user") !== "share" && (
                      <ListItem button>
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={secureLocalStorage.getItem("user")}
                        />
                      </ListItem>
                    )}

                  {isLoggedIn &&
                    secureLocalStorage.getItem("user") !== "share" && (
                      <a
                        className={clsx(classes.link)}
                        onClick={() => {
                          document.getElementById("uploadProject").click();
                        }}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <CloudUploadIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Upload Project"} />
                        </ListItem>
                      </a>
                    )}
                  <Link
                    className={clsx(classes.link)}
                    to={isLoggedIn ? "/" : "/login"}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Home"} />
                    </ListItem>
                  </Link>
                  {!isAdmin && (
                    <a
                      target={"_blank"}
                      className={clsx(classes.link)}
                      href={"https://forms.gle/exg7k1eea2k1wZg87"}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Feedback"} />
                      </ListItem>
                    </a>
                  )}
                  {!isAdmin && (
                    <a
                      target={"_blank"}
                      className={clsx(classes.link)}
                      href={"https://www.metageo.io/support"}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Help"} />
                      </ListItem>
                    </a>
                  )}
                  {secureLocalStorage.getItem("user") == "share" && (
                    <a
                      target={"_blank"}
                      className={clsx(classes.link)}
                      onClick={() => {
                        history.push("/signup");
                        dispatch(logout());
                        history.push("/signup");
                      }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Create Free Account"} />
                      </ListItem>
                    </a>
                  )}
                  {JSON.parse(secureLocalStorage.getItem("typeID")) == 2 &&
                    !isAdmin && (
                      <Link className={clsx(classes.link)} to={"/upgrade-plan"}>
                        <ListItem button>
                          <ListItemIcon>
                            <StoreIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Upgrade"} />
                        </ListItem>
                      </Link>
                    )}

                  {isAdmin && (
                    <>
                      <Link className={clsx(classes.link)} to={"/Dashboard"}>
                        <ListItem button>
                          <ListItemIcon>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Dashboard"} />
                        </ListItem>
                      </Link>
                      <Link
                        className={clsx(classes.link)}
                        to={"/Subscriptions"}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleAltIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Subscriptions"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/Users"}>
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleAltIcon />
                          </ListItemIcon>
                          <ListItemText primary={"All Users"} />
                        </ListItem>
                      </Link>

                      <Link
                        className={clsx(classes.link)}
                        to={"/InactiveUsers"}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Inactive Users"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/Admins"}>
                        <ListItem button>
                          <ListItemIcon>
                            <ImportantDevicesIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Admins"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/Restrictions"}>
                        <ListItem button>
                          <ListItemIcon>
                            <ImportantDevicesIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Restrictions"} />
                        </ListItem>
                      </Link>

                      <Link className={clsx(classes.link)} to={"/userLogs"}>
                        <ListItem button>
                          <ListItemIcon>
                            <CardMembershipIcon />
                          </ListItemIcon>
                          <ListItemText primary={"User Logs"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/errorLogs"}>
                        <ListItem button>
                          <ListItemIcon>
                            <CardMembershipIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Error Logs"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/fileLogs"}>
                        <ListItem button>
                          <ListItemIcon>
                            <CardMembershipIcon />
                          </ListItemIcon>
                          <ListItemText primary={"File Logs"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/Issues"}>
                        <ListItem button>
                          <ListItemIcon>
                            <BugReportIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Reported Issues"} />
                        </ListItem>
                      </Link>
                      <Link
                        className={clsx(classes.link)}
                        to={"/all-organizations"}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <BugReportIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Organizations"} />
                        </ListItem>
                      </Link>
                      <Link className={clsx(classes.link)} to={"/PolicyUpdate"}>
                        <ListItem button>
                          <ListItemIcon>
                            <PolicyIcon />
                          </ListItemIcon>
                          <ListItemText primary={"EULA"} />
                        </ListItem>
                      </Link>

                      <Link className={clsx(classes.link)} to={"/Tips"}>
                        <ListItem button>
                          <ListItemIcon>
                            <DoneAllIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Tips"} />
                        </ListItem>
                      </Link>
                    </>
                  )}

                  {isLoggedIn &&
                    !isAdmin &&
                    secureLocalStorage.getItem("user") !== "share" &&
                    (isOrganizationAdmin ||
                      !JSON.parse(
                        secureLocalStorage.getItem("organizationID")
                      )) && (
                      <>
                        <Link
                          className={clsx(classes.link)}
                          to={"/organization"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary={"My Team"} />
                          </ListItem>
                        </Link>
                        <Link
                          className={clsx(classes.link)}
                          to={"/organization-projects"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Team Sharing"} />
                          </ListItem>
                        </Link>
                      </>
                    )}

                  {!isLoggedIn && (
                    <Link className={clsx(classes.link)} to={"/signup"}>
                      <ListItem button>
                        <ListItemIcon>
                          <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Register"} />
                      </ListItem>
                    </Link>
                  )}

                  {isLoggedIn &&
                    secureLocalStorage.getItem("user") !== "share" && (
                      <>
                        <Link
                          className={clsx(classes.link)}
                          to={"/update-user"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Manage Profile"} />
                          </ListItem>
                        </Link>
                        {/* <a
                          target={"_blank"}
                          className={clsx(classes.link)}
                          href={"https://metageo.io/contact-us/"}
                        > */}
                        <Link
                          className={clsx(classes.link)}
                          to={"/upgrade-plan"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Upgrade Account"} />
                          </ListItem>
                        </Link>
                        <Link
                          className={clsx(classes.link)}
                          to={"/manage-plan"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Manage Plan"} />
                          </ListItem>
                        </Link>
                        {/* </a> */}
                        {/* <Link
                          className={clsx(classes.link)}
                          to={"/update-password"}
                        >
                          <ListItem button>
                            <ListItemIcon>
                              <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Update Password"} />
                          </ListItem>
                        </Link> */}
                      </>
                    )}

                  {isLoggedIn ? (
                    <Link className={clsx(classes.link)} onClick={logOut}>
                      <ListItem button>
                        <ListItemIcon>
                          <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                      </ListItem>
                    </Link>
                  ) : (
                    <Link className={clsx(classes.link)} to={"/login"}>
                      <ListItem button>
                        <ListItemIcon>
                          <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Login"} />
                      </ListItem>
                    </Link>
                  )}
                </List>
              </div>
            </Drawer>
          </a>
          <ul className="MenuList">
            {isLoggedIn && secureLocalStorage.getItem("user") !== "share" && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("uploadProject").click();
                  }}
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  Upload Project
                </a>
              </li>
            )}
            <li>
              <Link
                to={isLoggedIn ? "/" : "/login"}
                className="nav-link"
                style={{
                  color: secureLocalStorage.getItem("organizationText")
                    ? secureLocalStorage.getItem("organizationText")
                    : "#fff",
                }}
              >
                Home
              </Link>
            </li>
            {JSON.parse(secureLocalStorage.getItem("typeID")) == 2 && !isAdmin && (
              <li>
                <Link
                  to={"/upgrade-plan"}
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  Upgrade
                </Link>
              </li>
            )}
            {!isAdmin && (
              <li>
                <a
                  target={"_blank"}
                  href={"https://forms.gle/exg7k1eea2k1wZg87"}
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  Feedback
                </a>
              </li>
            )}
            {!isAdmin && (
              <li>
                <a
                  target={"_blank"}
                  href={"https://www.metageo.io/support"}
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  Help
                </a>
              </li>
            )}
            {secureLocalStorage.getItem("user") == "share" && (
              <li>
                <a
                  target={"_blank"}
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                  onClick={() => {
                    history.push("/signup");
                    dispatch(logout());
                    history.push("/signup");
                  }}
                >
                  Create Free Account
                </a>
              </li>
            )}
            {isLoggedIn &&
              !isAdmin &&
              secureLocalStorage.getItem("user") !== "share" && (
                <li>
                  <a
                    href="#."
                    id="createHighlight"
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    {!JSON.parse(secureLocalStorage.getItem("restrictions"))
                      ?.whiteLabel ? (
                      <img
                        id="companyLogo"
                        style={{
                          margin: "-2px 5px 0px 0px",
                          height: 20,
                          display: secureLocalStorage.getItem(
                            "organizationLogo"
                          )
                            ? ""
                            : "none",
                        }}
                        src={secureLocalStorage.getItem("organizationLogo")}
                      />
                    ) : null}
                    {secureLocalStorage.getItem("organizationName") &&
                    secureLocalStorage.getItem("organizationName") != "null" &&
                    secureLocalStorage.getItem("organizationName") !=
                      "undefined"
                      ? secureLocalStorage.getItem("organizationName")
                      : "Create Team"}
                  </a>
                  {isOrganizationAdmin ||
                  !JSON.parse(secureLocalStorage.getItem("organizationID")) ? (
                    <>
                      <ul>
                        <li>
                          <Link to={"/organization"} className="nav-link">
                            Manage
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/organization-projects"}
                            className="nav-link"
                          >
                            Team Sharing
                          </Link>
                        </li>
                      </ul>
                    </>
                  ) : null}
                </li>
              )}
            {isAdmin && (
              <>
                <li>
                  <Link
                    to={"/Dashboard"}
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Subscriptions"}
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    Subscriptions
                  </Link>
                </li>
                <li>
                  <a
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    Users
                  </a>
                  <ul>
                    <li>
                      <Link to={"/Users"} className="nav-link">
                        All Users
                      </Link>
                    </li>
                    <li>
                      <Link to={"/InactiveUsers"} className="nav-link">
                        Inactive Users
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Admins"} className="nav-link">
                        Admins
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Restrictions"} className="nav-link">
                        Restrictions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/userLogs"} className="nav-link">
                        User Logs
                      </Link>
                    </li>
                    <li>
                      <Link to={"/errorLogs"} className="nav-link">
                        Error Logs
                      </Link>
                    </li>
                    <li>
                      <Link to={"/fileLogs"} className="nav-link">
                        File Logs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={"/Issues"}
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    Reported Issues
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/all-organizations"}
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    Organizations
                  </Link>
                </li>
                <li>
                  <a
                    className="nav-link"
                    style={{
                      color: secureLocalStorage.getItem("organizationText")
                        ? secureLocalStorage.getItem("organizationText")
                        : "#fff",
                    }}
                  >
                    User Communication
                  </a>
                  <ul>
                    <li>
                      <Link to={"/PolicyUpdate"} className="nav-link">
                        EULA
                      </Link>
                    </li>
                    <li>
                      <Link to={"/eula-history"} className="nav-link">
                        EULA HISTORY
                      </Link>
                    </li>
                    <li>
                      <Link to={"/Tips"} className="nav-link">
                        Tips
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            {isLoggedIn ? (
              <li>
                <a
                  href="#."
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  <AccountCircleIcon
                    style={{ margin: "-10px 2px -7px 0px" }}
                  ></AccountCircleIcon>
                  {secureLocalStorage.getItem("user") !== "share"
                    ? secureLocalStorage.getItem("user")
                    : ""}
                </a>
                <ul>
                  {!isAdmin && secureLocalStorage.getItem("user") !== "share" && (
                    <li>
                      <Link to={"/update-user"} className="nav-link">
                        Manage Profile
                      </Link>
                    </li>
                  )}
                  {!isAdmin && secureLocalStorage.getItem("user") !== "share" && (
                    <>
                      <li>
                        {/* <a
                        target={"_blank"}
                        href={"https://metageo.io/contact-us/"}
                        className="nav-link"
                      > */}
                        <Link
                          to={"/upgrade-plan"}
                          className="nav-link"
                          // style={{
                          //   color: secureLocalStorage.getItem(
                          //     "organizationText"
                          //   )
                          //     ? secureLocalStorage.getItem("organizationText")
                          //     : "#fff",
                          // }}
                        >
                          Upgrade Account
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/manage-plan"}
                          className="nav-link"
                          // style={{
                          //   color: secureLocalStorage.getItem(
                          //     "organizationText"
                          //   )
                          //     ? secureLocalStorage.getItem("organizationText")
                          //     : "#fff",
                          // }}
                        >
                          Manage Plan
                        </Link>
                        {/* </a> */}
                      </li>
                    </>
                  )}
                  {/* {secureLocalStorage.getItem("user") !== "share" && (
                    <li>
                      <Link to={"/update-password"} className="nav-link" style={{ color:secureLocalStorage.getItem("organizationText")
          ? secureLocalStorage.getItem("organizationText"):"#fff" }}>
                        Update Password
                      </Link>
                    </li>
                  )} */}
                  <li>
                    <Link onClick={logOut}> Logout </Link>
                  </li>
                </ul>
              </li>
            ) : (
              <li>
                <a
                  className="nav-link"
                  style={{
                    color: secureLocalStorage.getItem("organizationText")
                      ? secureLocalStorage.getItem("organizationText")
                      : "#fff",
                  }}
                >
                  Login/Register
                </a>
                <ul>
                  <li>
                    <Link to={"/login"} className="nav-link">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link to={"/signup"} className="nav-link">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
