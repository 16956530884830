import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip, Button } from "@material-ui/core";
import {
  getOrganization,
  updateOrganization,
} from "../../services/user.service";
import { useDispatch } from "react-redux";
import { displayMessage } from "./../common/common";
import { LOADING_MESSAGE } from "./../../actions/types";
import secureLocalStorage from "react-secure-storage";

function OrgTags() {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);

  const handleSubmit = () => {
    console.log("Submitted Tags:", tags); // Replace with your desired logic

    if (JSON.parse(secureLocalStorage.getItem("organizationID"))) {
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      updateOrganization(
        { tags: tags },
        JSON.parse(secureLocalStorage.getItem("organizationID"))
      )
        .then((res, error) => {
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
          dispatch(displayMessage("success", "Tags updated successfully"));
          if (error) {
            dispatch(
              displayMessage(
                "error",
                error.response
                  ? error.response.data.message
                  : "Tags update error"
              )
            );
          }
        })
        .catch((error) => {
          dispatch(
            displayMessage(
              "error",
              error.response ? error.response.data.message : "Tags update error"
            )
          );
        });
    }
  };

  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });

    if (JSON.parse(secureLocalStorage.getItem("organizationID"))) {
      getOrganization(JSON.parse(secureLocalStorage.getItem("organizationID")))
        .then((res, err) => {
          if (res.status == 200) {
            dispatch({ type: LOADING_MESSAGE, isLoading: false });
            if (res?.data?.organization?.tags != null) {
              setTags(res.data.organization.tags);
            }
            console.log(
              "getOrganization",
              res,
              secureLocalStorage.getItem("organizationID")
            );
          }
        })
        .catch((error) => {
          dispatch(
            displayMessage(
              "error",
              error.response ? error.response.data.message : message
            )
          );
        });
    } else {
      dispatch({ type: LOADING_MESSAGE, isLoading: false });
    }
    sessionStorage.setItem("reloadMap", 1);
  }, []);

  return (
    <div
      style={{
        paddingTop: "85px",
        textAlign: "center",
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <h1 style={{ color: "#a140b1", fontSize: 50 }}>Organization Tags</h1>
      <div style={{ margin: "0 auto", width: "500px" }}>
        <Autocomplete
          multiple
          freeSolo
          value={tags}
          options={[]} // Static options can be added here
          onChange={(event, newValue) => setTags(newValue)}
          getOptionLabel={(option) => option}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option}
                {...getTagProps({ index })}
                variant="outlined"
                style={{
                  fontSize: "0.9rem",
                  height: "24px",
                  margin: "2px",
                  maxWidth: "120px",
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Tags"
              placeholder="Add tags"
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: "0.9rem",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "0.9rem",
                },
              }}
            />
          )}
        />
      </div>
      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{
          marginTop: "10px",
        }}
      >
        Submit
      </Button>
    </div>
  );
}

export default OrgTags;
